﻿import { latLng } from "./latLng";

export class ridge2ClientOptions {
    zoom: number;
    tileSource: string;
    opacity: number;
    center: latLng;
    fitBounds: latLng[];
    animate: boolean;
    animateIncrement: number;
    animateInterval: number;

    static parseParams(params: any): ridge2ClientOptions {
        var o = new ridge2ClientOptions();

        o.zoom = parseInt(params.zoom || 7);
        if (params.latitude && params.longitude) {
            o.center = {
                latitude: parseFloat(params.latitude),
                longitude: parseFloat(params.longitude)
            };
        }
        else {
            o.center = { latitude: 0, longitude: 0 }
        }
        o.tileSource = params.tileSource;
        o.opacity = parseFloat(params.opacity || .7);
        if (params.fitBounds) {
            var fitBounds = JSON.parse(params.fitBounds + '');
            o.fitBounds = [
                { latitude: fitBounds[0][0], longitude: fitBounds[0][1] },
                { latitude: fitBounds[1][0], longitude: fitBounds[1][1] }
            ];
        }
        o.animate = (<Object>params).hasOwnProperty('animate');
        o.animateIncrement = parseInt(params.animateIncrement || 10);
        o.animateInterval = parseInt(params.animateInterval || 200);

        return o;
    }
}